<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <b-sidebar
      id="sidebar-question-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="620px"
    >
      <sidebar-template title="Question Details" :closeAction="closeDetailsSidebar">
        <template #content>
          <response-question-details-view
            :response="selectedResponse"
            :reloadParent="load"
            :assessmentStatus="assessment.status"
            :disableActions="true"
          />
        </template>
      </sidebar-template>
    </b-sidebar>

    <b-sidebar
      lazy
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <response-form-card
        v-if="selectedResponse !== null"
        :reloadParent="load"
        :response="selectedResponse"
        :closeSidebar="closeSidebar"
        :questionnaireVendorId="questionnaireVendorId"
        :fullquestions="responses"
        :qindex="qindex"
      />
    </b-sidebar>
    <div class="card" v-if="assessment">
      <div class="card-header d-flex flex-row align-items-start justify-content-between">
        <div
          class="d-flex flex-column align-items-start justify-content-start"
          style="width: 50%"
        >
          <span class="d-flex align-items-center justify-content-start"
            ><h2 class="font-weight-bold mr-50 mb-0">{{ assessment.title }}</h2>
            <b-badge variant="success" v-if="markstatus == 2"
              ><feather-icon icon="CheckCircleIcon" class="mr-25" /><span
                class="align-middle"
                >Submitted</span
              ></b-badge
            ></span
          >

          <p>{{ assessment.description }}</p>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-between"
          style="width: 30%"
        >
          <h4>Progress</h4>
          <div class="d-flex justify-content-center align-items-center">
            <donut-bar
              :percentage="Number(assessment.completion.toFixed(2))"
              :displayValue="assessment.completion + '%'"
              size="sm"
              :uniqueId="assessment._id + 2"
              :colorsReversed="true"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 
    <div class="card">
      <div class="card-header d-flex flex-column align-items-start justify-content-start">
        <h4 class="text-turncate mb-50">Questions & Responses</h4>
        <p v-if="isUserVendor == true" class="mb-0 font-weight-bold">
          Click on any question to start the assessment.
        </p>
        <p v-else class="mb-0 font-weight-bold">
          Click on any question to view the assessment.
        </p>
      </div>
      <div class="float-right">
        <button
          class="btn btn-success mb-1 float-right mr-1"
          @click="startAssess()"
          v-if="markstatus == 1"
        >
          <span v-if="is_new">Start Assessment</span>
          <span v-else>Continue Assessment</span>
        </button>
      </div>
      <div class="card-body">
        <div
          class="d-flex cursor-pointer justify-content-between align-items-center border mb-1 p-1 rounded"
          style="background: #767187; color: #fff"
        >
          <div class="font-weight-bold" style="width: 70%">Question</div>
          <div class="d-flex">
            <p>Response</p>
            <p class="ml-2">Evidence</p>
          </div>
        </div>
        <div
          :class="
            markstatus == 1
              ? 'd-flex cursor-pointer justify-content-between p-1 border mb-1 rounded'
              : 'd-flex cursor-pointer justify-content-between p-1 border mb-1 rounded not-active'
          "
          v-for="(question, i) in responses"
          :key="question._id"
          @click="handleQuestionClick(question, i)"
          title="Click to start assessment"
          style="border-left: 3px solid #7367f0 !important"
        >
          <div class="font-weight-bold d-flex" style="width: 70%">
            <span class="text-primary mr-1">
              <b-badge variant="primary" v-if="question.question_number">
                {{ i + 1 }}
              </b-badge>
            </span>
            <span>{{ question.title }}</span>
          </div>
          <div
            style="width: 30%; display: flex; align-items: center; justify-content: end"
          >
            <div>
              <b-badge
                variant="warning"
                v-if="!question.downloadable_evidences.length"
                class="mr-1"
              >
                <span v-if="question.is_evidence_rqd == 1">Evidence Required</span>
              </b-badge>
            </div>

            <div class="mr-3">
              <b-badge variant="primary">{{
                getResponseValue(question.response)
              }}</b-badge>
            </div>
            <div v-if="question.evidences.length && question.response" class="ml-1">
              <feather-icon icon="CheckCircleIcon" class="text-success" />
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <button
            class="btn btn-success ml-1"
            @click="markCompletePopUp"
            v-if="markstatus == 1"
          >
            <span>Submit to Assigner</span>
          </button>
        </div>
      </div>
    </div> -->

    <div class="card">
      <div class="card-header pb-75">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex flex-column align-items-start justify-content-start">
            <h4 class="mb-25 font-weight-bold">Questions & Responses</h4>
            <p class="mb-0">Click on any question to start the assessment.</p>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-end">
            <b-button
              size="sm"
              variant="success"
              class="mr-1"
              @click="markCompletePopUp"
              v-if="markstatus == 1"
            >
              <span class="align-middle text-center">
                <feather-icon icon="CheckIcon" class="mr-25" />
                <span>Submit to Assigner</span>
              </span>
            </b-button>

            <b-button
              size="sm"
              variant="outline-primary"
              @click="startAssess()"
              v-if="markstatus == 1"
            >
              <span v-if="is_new">Start Assessment</span>
              <span v-else
                >Continue Assessment
                <feather-icon icon="ArrowRightCircleIcon" class="ml-25" /></span
            ></b-button>
          </div>
        </div>
      </div>

      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
      </div>
      <table role="table" class="table table-hover">
        <thead role="rowgroup">
          <tr role="row">
            <th role="columnheader" scope="col">#</th>
            <th role="columnheader" scope="col">Question</th>
            <th role="columnheader" scope="col" class="text-center">Response</th>
            <th role="columnheader" scope="col" class="text-center">Evidence Status</th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <template
            v-if="
              filteredResponses &&
              filteredResponses != null &&
              filteredResponses.length > 0
            "
          >
            <tr
              v-for="(question, i) in filteredResponses"
              :key="question._id"
              role="row"
              class="cursor-pointer"
              @click="
                () => {
                  if (markstatus != 1) {
                    handleDetailsViewClick(question);
                  } else {
                    handleQuestionClick(question, i);
                  }
                }
              "
            >
              <td role="cell">
                <div class="d-flex justify-content-start">
                  <p class="text-left font-weight-bold mb-0">
                    {{ i + 1 }}
                  </p>
                </div>
              </td>
              <!-- <td>{{ question.question_number }}</td> -->
              <td role="cell" style="max-width: 30vw">
                <div
                  class="w-100 d-flex flex-column align-items-start justify-content-center"
                >
                  <p class="text-primary font-weight-bold mb-25">
                    {{ question.title }}
                  </p>
                  <small class="text-left mb-25">
                    {{ question.description }}
                  </small>
                  <b-badge
                    variant="light-warning"
                    v-if="!question.downloadable_evidences.length"
                  >
                    <span v-if="question.is_evidence_rqd == 1">Evidence Required</span>
                  </b-badge>
                </div>
              </td>

              <td role="cell">
                <div class="d-flex align-items-center justify-content-center w-100">
                  <b-badge :variant="getResponseVariant(question.response)">{{
                    getResponseValue(question.response)
                  }}</b-badge>
                </div>
              </td>

              <td>
                <div class="d-flex align-items-center justify-content-center w-100">
                  <div v-if="question.evidences.length && question.response">
                    <b-badge variant="success"
                      ><span class="align-middle text-center">
                        <feather-icon icon="CheckCircleIcon" class="mr-25" />
                        <span>Submitted</span>
                      </span></b-badge
                    >
                  </div>
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <empty-table-section title="Questions Empty">
              <template #content>
                <p class="text-center">It seems there are no questions at the moment.</p>
              </template>
            </empty-table-section>
          </template>
        </tbody>
      </table>
      <!-- <div class="card-body">
        <div class="d-flex align-items-center justify-content-center w-100">
          <b-pagination
            v-model="filters.page"
            :per-page="pagination.perPage"
            :total-rows="pagination.total"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div> -->
    </div>

    <b-modal
      title="Are you sure?"
      ok-only
      @ok="markComplete"
      ref="mark-complete"
      size="lg"
    >
      <h5>
        Out of {{ this.assessment.total_questions }} questions
        <!-- {{ this.currentAssessIndex }} answered ! Are you sure to submit ? this action -->
        {{ this.responses.filter(item=>item.status).length }} answered ! Are you sure to submit ? this action
        can't be edit.
      </h5>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BSidebar,
  BButton,
  BAvatar,
  BBadge,
  BModal,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
} from "bootstrap-vue";
import DonutBar from "@/components/DonutBar.vue";
import ThirdPartyRisksMixins from "../../../mixins/ThirdPartyRisksMixins";
import ThirdPartyRisksRAMixins from "../../../mixins/ThirdPartyRisksRAMixins";
import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";
import UtilsMixins from "../../../mixins/UtilsMixins";
import TaskMixins from "../../../mixins/TaskMixins";
import VendorMixins from "../../../mixins/VendorMixins";
import ResponseFormCard from "./components/ResponseFormCard.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";
import EmptyTableSection from "../../../components/EmptyTableSection.vue";
import SidebarTemplate from "../../../components/SidebarTemplate.vue";
import ResponseQuestionDetailsView from "../ThirdPartyRisksRA/components/ResponseQuestionDetailsView.vue";
export default {
  components: {
    BOverlay,
    BSidebar,
    BButton,
    BAvatar,
    DonutBar,
    ResponseFormCard,
    BBadge,
    BModal,
    FeatherIcon,
    EmptyTableSection,
    SidebarTemplate,
    ResponseQuestionDetailsView,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
  },
  data() {
    return {
      showOverlay: false,
      vendor: null,
      selectedResponse: [],
      activeResponseId: null,
      currentResponseIndex: 0,
      importedQuestionnaire: null,
      questionnaireVendorId: null,
      assessment: null,
      responses: [],
      filters: {
        page: 1,
        perPage: 10,
        lastPage: 1,
        search: null,
      },
      isUserVendor: false,
      assessmentProgress: 0,
      allquestions: [],
      qindex: "",
      currentAssessIndex: null,
      restAssessmentqs: [],
      showStartbtn: true,
      markstatus: "",
      is_new: true,
    };
  },

  mixins: [
    ThirdPartyRisksMixins,
    ThirdPartyRisksRAMixins,
    QuestionnaireMixins,
    ResponseMixins,
    UtilsMixins,
    TaskMixins,
    VendorMixins,
  ],

  computed: {
    filteredResponses() {
      if (!this.responses) {
        return [];
      }
      let filtered = this.responses;
      // Apply search filter
      if (this.filters.search) {
        const searchTerm = this.filters.search.toLowerCase();
        filtered = filtered.filter((question) => {
          return (
            question.title.toLowerCase().includes(searchTerm) ||
            question.description.toLowerCase().includes(searchTerm)
          );
        });
      }
      return filtered;
    },
  },

  mounted() {
    this.load();
    // this.startBtn();
  },
  methods: {
    handleDetailsViewClick(response) {
      this.selectedResponse = response;
      this.openDetailsSidebar();
    },

    closeDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-question-details");
    },
    openDetailsSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-question-details");
      });
    },

    markCompletePopUp() {
      if(this.assessment.total_questions===this.responses.filter(item=>item.status).length)
        this.markComplete()
      else
        this.$refs["mark-complete"].show();
    },
    markComplete() {
      const data = {
        status: 2,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          `/vendors/${this.$store.state.app.user.vendor_id}/assessments/${this.$route.params.assessmentId}/status`,
      };
      this.$http(options)
        .then((res) => {
          this.load();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    currentAssesmentqs() {
      var i = 0;
      for (i = 0; i < this.responses.length; i++) {
        if (this.responses[i].status == null) {
          this.currentAssessIndex = i;
          if (i > 0) {
            break;
          }
        } else {
          this.is_new = false;
        }
      }
    },
    startAssess() {
      if (this.currentAssessIndex != null) {
        this.handleQuestionClick(
          this.responses[this.currentAssessIndex],
          this.currentAssessIndex
        );
      }
    },

    load() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        this.isUserVendor = true;
      } else {
        this.isUserVendor = false;
      }
      this.getAssessmentDetailsAndSetData(
        this.$store.state.app.user.vendor_id,
        this.$route.params.assessmentId
      );
    },

    getAssessmentDetailsAndSetData(vendorId, assessmentId) {
      this.getAssessmentDetailsOfVendor(vendorId, assessmentId)
        .then((res) => {
          this.responses = [];
          this.assessment = res.data.data;
          this.allquestions = res.data.data.questionnaires;
          this.markstatus = res.data.data.status;
          let questionnaires = res.data.data.questionnaires;
          let allProgress = 0;
          questionnaires.map((questionnaire) => {
            allProgress = allProgress + questionnaire.completion;
            questionnaire.responses.map((response) => {
              // console.log("Response", response);

              response["vendor_id"] = questionnaire.vendor_id;
              //Dont Show Not Applicable
              if (
                (response.parent_question_id == null &&
                  response.is_response_mandatory == 1) ||
                (response.parent_question_id != null &&
                  response.parent_response.response == response.parent_question_response)
              ) {
                response["response_options"] = [
                  { value: null, text: "--Select a value--" },
                  { value: 1, text: "Yes" },
                  { value: 2, text: "No" },
                ];
              } else {
                response["response_options"] = [
                  { value: null, text: "--Select a value--" },
                  { value: 1, text: "Yes" },
                  { value: 2, text: "No" },
                  { value: 3, text: "Not Applicable" },
                ];
              }
              this.responses.push(response);
            });
          });
          this.assessmentProgress = allProgress / questionnaires.length;

          this.currentAssesmentqs(); //checking assesment is start or continue
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleQuestionClick(question, index) {
      this.qindex = index;
      this.questionnaireVendorId = question.vendor_id;
      this.setSelectedResponseData(question);
      this.openSidebar();
    },
    setSelectedResponseData(response) {
      this.selectedResponse = response;
      this.activeResponseId = response._id;
      this.currentResponseIndex = this.responses.findIndex(
        (res) => res._id == response._id
      );
    },
    getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return "Unknown";
        }
      }
    },

    getResponseVariant(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "success";
          case 2:
            return "danger";
          case 3:
            return "primary";
          default:
            return "dark";
        }
      }
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },

    getStatusNumber(num) {
      switch (num) {
        case 1:
          return "In Progress";
        case 2:
          return "Completed";
        default:
          return "Unknown";
      }
    },
    getStatusColor(num) {
      switch (num) {
        case 1:
          return "info";
        case 2:
          return "success";
        default:
          return "info";
      }
    },
  },
};
</script>

<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem;
}
.not-active {
  pointer-events: none;
  cursor: default;
}
</style>

<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>
